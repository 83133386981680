import React, { useCallback, useState } from "react";
import cn from "classnames";
import minusActiveSVG from "images/minus-active.svg";
import minusDisabledSVG from "images/minus-disabled.svg";
import minusSVG from "images/minus.svg";
import plusActiveSVG from "images/plus-active.svg";
import plusDisabledSVG from "images/plus-disabled.svg";
import plusSVG from "images/plus.svg";
import styled from "styled-components";

export default function NumberInput(props) {
  const { name, min, max, step, value, onChange: propsOnChange } = props;

  const [inputFocus, setInputFocus] = useState(false);
  const [message, setMessage] = useState("");
  const onChangeMessage = useCallback((message) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 3000);
  }, []);

  const minInt = parseInt(min);
  const maxInt = parseInt(max);
  const valueInt = parseInt(value);

  // onChange enforces numbers only and maximum 2 digits
  // Note we do this in onChange to handle copy paste scenarios which we would
  // miss with something like onKeyPress
  const onChange = useCallback(
    (e) => {
      let stripped = e.currentTarget.value.replace(/\D/g, "");
      if (stripped.length > 2) {
        stripped = stripped.substring(0, 2);
      }
      if (stripped !== e.currentTarget.value) {
        e.currentTarget.value = stripped;
      }
      if (propsOnChange) {
        propsOnChange(stripped);
      }
    },
    [propsOnChange]
  );

  // onBlur enforces min and max boundary
  const onBlur = useCallback(
    (e) => {
      setInputFocus(false);
      let stripped = e.currentTarget.value || "0";
      if (parseInt(stripped) < minInt) {
        stripped = min;
        onChangeMessage("Adjusted to min 3");
      }
      if (parseInt(stripped) > maxInt) {
        stripped = max;
        onChangeMessage("Adjusted to max 99");
      }
      if (stripped !== e.currentTarget.value) {
        e.currentTarget.value = stripped;
      }
      if (propsOnChange) {
        propsOnChange(stripped);
      }
    },
    [min, minInt, max, maxInt, propsOnChange, onChangeMessage]
  );

  const onFocus = useCallback(() => {
    setInputFocus(true);
  }, []);

  const onMinus = (e) => {
    e.stopPropagation();
    if (valueInt > minInt) {
      props.onChange(valueInt - 1);
    }
  };

  const onPlus = (e) => {
    e.stopPropagation();
    if (valueInt < maxInt) {
      props.onChange(valueInt + 1);
    }
  };

  return (
    <S.NumberInput className="number-input">
      <div
        className={cn("button minus", { disabled: valueInt <= minInt })}
        onClick={onMinus}
      />
      <span className={cn("input-wrap", { active: inputFocus })}>
        <span className="expander" aria-hidden="true" />
        <input
          className={cn({ active: inputFocus })}
          type="number"
          name={name}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </span>
      <div
        className={cn("button plus", { disabled: valueInt >= maxInt })}
        onClick={onPlus}
      />
      {message && <div className="messages">{message}</div>}
    </S.NumberInput>
  );
}

const S = {};

// We need a hidden span to autosize this thing
S.NumberInput = styled.div`
  display: inline-flex;
  background: #31353b;
  flex: 0 0 auto;
  position: relative;
  .input-wrap {
    position: relative;
    .expander {
      padding: 0 2.6rem;
      color: yellow;
      visibility: hidden;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .input-wrap::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    background: ${({ theme }) => theme.greenDarker};
    height: 0%;
    width: 1px;
  }
  .input-wrap.active::before,
  .input-wrap:hover::before {
    height: 100%;
  }
  .input-wrap::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    background: ${({ theme }) => theme.greenDarker};
    height: 0%;
    width: 1px;
  }
  .input-wrap.active::after,
  .input-wrap:hover::after {
    height: 100%;
  }
  input {
    border: 1px solid ${({ theme }) => theme.darkGray3};
    border-left: none;
    border-right: none;
    color: white;
    text-align: center;
    cursor: pointer;
    background: transparent;
    transition: none;
    &.active,
    &:hover {
      border: 1px solid ${({ theme }) => theme.greenDarker};
      border-left: none;
      border-right: none;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .button {
    color: #b1bac2;
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.darkGray3};
    &:hover {
      border: 1px solid ${({ theme }) => theme.greenDarker};
    }
    &.minus {
      background: url(${minusSVG}) no-repeat center;
      background-size: 1.3rem 1.3rem;
      &:hover {
        background: url(${minusActiveSVG}) no-repeat center;
        background-size: 1.3rem 1.3rem;
      }
      &.disabled {
        background: url(${minusDisabledSVG}) no-repeat center;
        background-size: 1.3rem 1.3rem;
      }
    }
    &.plus {
      background: url(${plusSVG}) no-repeat center;
      background-size: 1.3rem 1.3rem;
      &:hover {
        background: url(${plusActiveSVG}) no-repeat center;
        background-size: 1.3rem 1.3rem;
      }
      &.disabled {
        background: url(${plusDisabledSVG}) no-repeat center;
        background-size: 1.3rem 1.3rem;
      }
    }
  }

  .messages {
    position: absolute;
    top: 100%;
    left 0;
    color: ${({ theme }) => theme.lightGray1};
    font-size: 1.4rem;
  }
`;
