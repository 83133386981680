import React, { useCallback, useLayoutEffect, useState } from "react";
import styled from "styled-components";

export default function Slider(props) {
  const { name, step, value } = props;

  const [bubbleStyle, setBubbleStyle] = useState({});

  const min = props.min || 0;
  const max = props.max || 100;
  const styleMin = { left: "8px" };
  const styleMax = { left: "calc(100% - 7px)" };

  const updateBubbleStyle = useCallback(
    (value) => {
      const current = Number.parseInt(value, 10);
      const newVal = ((current - min) * 100) / (max - min);
      const newValAdjusted = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
      setBubbleStyle({ left: newValAdjusted });
    },
    [min, max]
  );

  useLayoutEffect(() => {
    updateBubbleStyle(value);
  }, [value, updateBubbleStyle]);

  const onChange = (e) => {
    updateBubbleStyle(e.target.value);
    props.onChange(e);
  };

  return (
    <S.Slider>
      <input
        type="range"
        name={name}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />
      <div className="numbers">
        <div className="min" style={styleMin}>
          {min}
        </div>
        <div className="max" style={styleMax}>
          {max}
        </div>
        <div className="bubble" style={bubbleStyle}>
          {value}
        </div>
      </div>
    </S.Slider>
  );
}

const S = {};

// Extremely in-depth analysis of slider internals across browsers:
// https://css-tricks.com/sliding-nightmare-understanding-range-input/

// Showing value bubbles
// https://css-tricks.com/value-bubbles-for-range-inputs/

// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
// http://danielstern.ca/range.css/?ref=css-tricks#/
// https://www.cssportal.com/style-input-range/
// https://css-tricks.com/value-bubbles-for-range-inputs/

// Some useful generators:
// https://www.cssportal.com/style-input-range/
// Another good generator: http://danielstern.ca/range.css/#/

S.Slider = styled.div`
  width: 50%;
  // NOTE! nesting the :active, ::-webkit-... does not seem to work
  // So keep all of this flat
  input {
    // padding-right must be zero so that the slider can be pulled
    // all the way to the right
    padding: 0;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    margin: 8px 0;
    height: 18px;
    background: transparent;
  }
  input::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background: #060606;
    height: 10px;
    border-radius: 9px;
  }
  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    margin-top: -4px;
    border-radius: 50%;
    background: #00ab85;
    cursor: pointer;
    box-shadow: #1a262e 0 0 0 3px, #b1bac2 0 0 0 4px;
  }
  input:focus {
    outline: none;
  }
  input:hover::-webkit-slider-thumb {
    box-shadow: none;
  }
  input:active::-webkit-slider-thumb {
    border: 2px solid #b1bac2;
    box-shadow: none;
  }
  .numbers {
    display: flex;
    position: relative;
    color: #5e6770;
    height: 1.7em;
    div {
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }
    .bubble {
      color: white;
    }
  }
`;
