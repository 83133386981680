import React, { useEffect, useRef, useState } from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import {
  Checkbox,
  NumberInput,
  RadioButton,
  RadioGroup,
  Slider,
} from "controls";
import { Col, Row } from "reactstrap";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import {
  FullBackground,
  GetStartedPageContainer,
  GetStartedPricingTable,
  Layout,
  LinkToOpenInNewWindow,
  SEO,
  ThreadPopup,
} from "components";
import { navigate } from "gatsby";
import { pricingLookupTableData, routes } from "utils";

const S = {};

const { capacityParams } = pricingLookupTableData;

export default function GetStartedPricingPage({ location }) {
  // Check if pricing has basic information to display. If not, that means
  // the user navigated to this url directly, so we would like to redirect them
  // back to the Get Started page
  // Note: useEffect throws error in console in local dev, but is fine in compiled preview/prod.
  const basicDisplayText = capacityParams.Basic.DisplayText;
  const standardDisplayText = capacityParams.Standard.DisplayText;
  const performanceDisplayText = capacityParams.Performance.DisplayText;
  useEffect(() => {
    // if (!location.state?.accountType) {
    //   console.log("getStartedPricing redirecting to getStartedLanding");
    //   navigate(routes("getStartedLanding"), { replace: true });
    // }
    // Temporarily taking the self-pricing pages offline by redirecting to landing
    console.log("getStartedPricing redirecting to getStartedLanding");
    navigate(routes("getStartedLanding"), { replace: true });
  }, [location.state]);

  const topRef = useRef(null);

  // TODO: Keep defaults for testing purposes?
  let accountType = location.state?.accountType || "Startup";
  const [showOptional, setShowOptional] = useState(false);
  const [modal, setModal] = useState(false);

  const [state, setState] = useState(
    location.state?.data || {
      capacity: basicDisplayText,
      renewal: "1 year",
      licenses: capacityParams.Basic.LicenseCountDefault,
      storage: "1",
      tutoring: "1",
      methods: capacityParams.Basic.MethodDevelopDefault,
      training: "Level 1 Open",
      level2: false,
      level3: false,
      level4: false,
      level5: false,
    }
  );

  const onClick = () => setModal(!modal);

  const onChange = (e) => {
    // Since setState is async we need to persist or store the event in a variable
    const { target } = e;
    // console.log('onChange', e.target.name, e.target.value);
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const getStorageDefaultFromLicenseCount = (LicenseCount) => {
    if (LicenseCount <= 5) {
      return "1";
    } else if (LicenseCount <= 10) {
      return "2";
    }
    return "3";
  };

  const onLicenseChange = (value) => {
    setState((prevState) => {
      return {
        ...prevState,
        licenses: value,
        storage: getStorageDefaultFromLicenseCount(value),
      };
    });
  };

  const onCapacityChange = (e) => {
    const capacity = e.target.value;
    const licenses = capacityParams[capacity].LicenseCountDefault;
    setState((prevState) => {
      return {
        ...prevState,
        capacity,
        licenses,
        methods: capacityParams[capacity].MethodDevelopDefault,
        storage: getStorageDefaultFromLicenseCount(licenses),
      };
    });
  };

  const description = "Customize an account to meet your laboratory needs";
  const { LicenseCountMin, LicenseCountDefault } =
    capacityParams[state.capacity];
  return (
    <FullBackground>
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={`Get Started with ECL | ${
            accountType === "Startup"
              ? "Startup Companies"
              : "Academia & Non-profit Investigators"
          } Pricing`}
          description={description}
          uri={location.pathname}
        />
        <GetStartedPageContainer
          state={{ accountType }}
          step="pricing"
          showHeader
          showLearnMore
        >
          <S.MainSelections>
            <S.Selection>
              <S.Title>
                <div>1.</div>
                Select Capacity
              </S.Title>
              <S.SelectionBody>
                <S.Desc>
                  ECL account capacity is a measure of the number of
                  simultaneous experiments an account can conduct. All accounts
                  can execute an unlimited number of sequential experiments
                  back-to-back, but are priced (basic, standard and performance
                  capacity) by the number of simultaneous experiments that can
                  be performed in parallel.
                  <br />
                  <S.ModalLink onClick={onClick}>
                    Learn more about capacities.
                  </S.ModalLink>
                </S.Desc>
                <RadioGroup>
                  <RadioButton
                    name="capacity"
                    value={basicDisplayText}
                    label={basicDisplayText}
                    checked={state.capacity === basicDisplayText}
                    onChange={onCapacityChange}
                  />
                  <RadioButton
                    name="capacity"
                    value={standardDisplayText}
                    label={standardDisplayText}
                    checked={state.capacity === standardDisplayText}
                    onChange={onCapacityChange}
                  />
                  <RadioButton
                    name="capacity"
                    value={performanceDisplayText}
                    label={performanceDisplayText}
                    checked={state.capacity === performanceDisplayText}
                    onChange={onCapacityChange}
                  />
                </RadioGroup>
              </S.SelectionBody>
            </S.Selection>
            <S.Selection>
              <S.Title>
                <div>2.</div>
                Number of ECL Command Center&reg; Software Licenses
              </S.Title>
              <S.SelectionBody>
                <S.Desc>
                  ECL Command Center is the software application used to
                  remotely drive all instrumentation and analyze, visualize and
                  interpret data. Licenses are per user login and can be
                  installed on any number of computers.
                  <br />
                  {LicenseCountDefault} licenses included.
                </S.Desc>
                <NumberInput
                  key={`license-count-min-${LicenseCountMin}`}
                  type="range"
                  name="licenseCount"
                  min={LicenseCountMin}
                  max="99"
                  step="1"
                  value={state.licenses}
                  onChange={onLicenseChange}
                />
              </S.SelectionBody>
            </S.Selection>
            <S.Selection>
              <S.Title>
                <div>3.</div>
                ECL Constellation&reg; Database Storage (TB)
              </S.Title>
              <S.SelectionBody>
                <S.Desc>
                  ECL Constellation automatically organizes your data into a
                  powerful knowledge graph, growing over time as you conduct
                  more experiments. Database storage limits can be increased as
                  capacity is reached.
                  <br />1 TB included.
                </S.Desc>
                <RadioGroup>
                  <RadioButton
                    name="storage"
                    value="1"
                    label="1 TB"
                    labelExtra="Recommended for 5 licenses or fewer"
                    checked={state.storage === "1"}
                    onChange={onChange}
                  />
                  <RadioButton
                    name="storage"
                    value="2"
                    label="2 TB"
                    labelExtra="Recommended for 6 to 10 licenses"
                    checked={state.storage === "2"}
                    onChange={onChange}
                  />
                  <RadioButton
                    name="storage"
                    value="3"
                    label="3 TB"
                    labelExtra="Recommended for 11 licenses or more"
                    checked={state.storage === "3"}
                    onChange={onChange}
                  />
                </RadioGroup>
              </S.SelectionBody>
            </S.Selection>
          </S.MainSelections>
          <S.Divider />
          <S.SectionHeader onClick={() => setShowOptional(!showOptional)}>
            <h3>Optional Add-Ons</h3>
            {showOptional ? <S.DownArrowIcon /> : <S.RightArrowIcon />}
          </S.SectionHeader>
          {showOptional && (
            <S.OptionalSelections>
              <S.Selection>
                <S.Title>
                  <div>4.</div>
                  Private Tutoring (Months)
                </S.Title>
                <S.SelectionBody>
                  <S.Desc>
                    Private tutoring allows you to schedule and meet with an ECL
                    specialist for up to 3 hours per week. During these meetings
                    we help you take advantage of the capabilities of the system
                    with your experiments, covering advanced topics such as
                    scripting, advanced data analysis, automatic report
                    generating and other topic at your request.
                    <br />1 month included.
                  </S.Desc>
                  <Slider
                    name="tutoring"
                    min="1"
                    max="12"
                    step="1"
                    value={state.tutoring}
                    onChange={onChange}
                  />
                </S.SelectionBody>
              </S.Selection>
              <S.Selection>
                <S.Title>
                  <div>5.</div>
                  Method Development
                </S.Title>
                <S.SelectionBody>
                  <S.Desc>
                    Let ECL onboard your existing workflow(s) or develop new
                    workflows for the Cloud Lab. ECL PhD scientists will
                    translate workflows into executable commands, test run all
                    steps, and deliver a ready-to-run cloud workflow. This
                    process includes regular meetings to discuss existing
                    methods, consider alternate experiments, and report progress
                    and results.
                  </S.Desc>
                  <Slider
                    name="methods"
                    min="1"
                    max="15"
                    step="1"
                    value={state.methods}
                    onChange={onChange}
                  />
                </S.SelectionBody>
              </S.Selection>
              <S.Selection>
                <S.Title>
                  <div>6.</div>
                  Certificate Training
                </S.Title>
                <S.SelectionBody>
                  <S.Desc>
                    Private sessions include certification training for up to 10
                    users and are available for an additional charge.
                    <br />
                    See{" "}
                    <LinkToOpenInNewWindow
                      href="/ECL_User_Certification_Program.pdf"
                      className="hover-underline"
                    >
                      Certification Program
                    </LinkToOpenInNewWindow>{" "}
                    for detailed description of each level.
                  </S.Desc>
                  <Checkbox
                    className="level1"
                    name="level1"
                    label="Level 1: Baseline Safety Certification Open Session"
                    labelExtra="Required"
                    checked
                    disabled
                  />
                  <RadioGroup>
                    <RadioButton
                      className="pricing-radio open-session"
                      name="training"
                      value="Level 1 Open"
                      label="Open Session"
                      labelExtra="Included"
                      checked={state.training === "Level 1 Open"}
                      onChange={onChange}
                    />
                    <RadioButton
                      className="pricing-radio"
                      name="training"
                      value="Level 1 Private Session"
                      checked={state.training === "Level 1 Private Session"}
                      onChange={onChange}
                    >
                      Private Session
                    </RadioButton>
                  </RadioGroup>
                  <Checkbox
                    name="level2"
                    checked={state.level2}
                    onChange={onChange}
                  >
                    Level 2: Advanced ECL Command Center&reg; Certification
                    Private Session
                  </Checkbox>
                  <Checkbox
                    name="level3"
                    checked={state.level3}
                    onChange={onChange}
                  >
                    Level 3: Advanced Data Analysis Certification Private
                    Session
                  </Checkbox>
                  <Checkbox
                    name="level4"
                    checked={state.level4}
                    onChange={onChange}
                  >
                    Level 4: Advanced Scripting Certification Private Session
                  </Checkbox>
                  <Checkbox
                    name="level5"
                    checked={state.level5}
                    onChange={onChange}
                  >
                    Level 5: Expert Programming Certification Private Session
                  </Checkbox>
                </S.SelectionBody>
              </S.Selection>
            </S.OptionalSelections>
          )}
          <S.Divider />
          <S.PlanDetails id="price-selection" ref={topRef}>
            <S.SectionHeader className="separate">
              <h3>Select Plan</h3>
            </S.SectionHeader>
            <GetStartedPricingTable
              step="pricing"
              accountType={accountType}
              state={state}
              handleChange={onChange}
              topRef={topRef}
            />
          </S.PlanDetails>
          <S.Footnote>
            All services are offered subject to ECL's standard{" "}
            <LinkToOpenInNewWindow
              className="sm hover-underline"
              href={routes("tos")}
            >
              Terms of Service
            </LinkToOpenInNewWindow>
          </S.Footnote>
          <ThreadPopup
            toggle={onClick}
            modal={modal}
            subsection={state.capacity.toLowerCase()}
          />
        </GetStartedPageContainer>
      </Layout>
    </FullBackground>
  );
}

S.MainSelections = styled.div``;

S.OptionalSelections = styled.div``;

// TODO: Constrain width of this container
S.SectionHeader = styled.div`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  display: flex;
  align-items: center;
  margin-bottom: 1.9rem;
  &.separate {
    justify-content: space-between;
  }
  h3 {
    font-weight: 500;
    line-height: 3rem;
    margin-bottom: 0;
    color: ${({ theme }) => theme.white};
  }
`;

S.Selection = styled.div`
  margin-bottom: 3rem;
  .pricing-radio {
    margin-left: 2.6rem;
    &.open-session {
      margin-bottom: 0;
    }
  }
`;

S.SelectionBody = styled.div`
  padding-left: 2.4rem;
`;
S.Title = styled.h4`
  position: relative;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.white};
  padding-left: 2.4rem;
  div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

S.Desc = styled.p`
  font-size: 1.7rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 2rem;
  margin-top: 0;
  margin-bottom: 2rem;
  color: #768594;
`;

S.ModalLink = styled.span`
  color: ${({ theme }) => theme.greenLink};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

S.LicenseDropdown = styled.select`
  // border: ${({ theme }) => (theme.crazy ? "5px solid orangered" : "unset")};
  background: white;
  color: #1d242a;
  flex: 1;
  border: none;
  text-transform: uppercase;
  // We need to keep this at 16px or larger so that iPhone doesn't zoom when the dropdown
  // is clicked.
  // https://stackoverflow.com/questions/6483425/prevent-iphone-from-zooming-in-on-select-in-web-app
  font-size: 1.6rem;
  &:focus {
    outline: none;
  }
`;

S.ControlGroup = styled.div`
  display: flex;
`;

S.Dropdown = styled.select`
  width: 15rem;
  margin-bottom: 1.2rem;
`;

S.Divider = styled.div`
  border-top: 1px solid #455059;
  margin-bottom: 1.9rem;
`;

S.PlanDetails = styled.div``;

S.DownloadLink = styled.div`
  align-items: center;
  display: flex;
  text-transform: uppercase;
`;

S.Footnote = styled.p`
  color: #768594;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.7rem;
  letter-spacing: 0;
  margin: 1rem 0;
`;

S.RightArrowIcon = styled(FaChevronRight)`
  color: ${({ theme }) => theme.white};
  margin-left: 1rem;
  padding: 0.2rem;
  height: 16px;
  background-color: #0cbd96;
  &.bare {
    background-color: transparent;
    color: ${({ theme }) => theme.greenLink};
    margin-left: 0.2rem;
  }
`;

S.DownArrowIcon = styled(FaChevronDown)`
  color: ${({ theme }) => theme.white};
  margin-left: 1rem;
  padding: 0.2rem;
  height: 16px;
  background-color: #0cbd96;
`;

S.Row = styled(Row)``;

S.Col = styled(Col)``;
