import React from "react";
import styled from "styled-components";

export function RadioGroup(props) {
  const { children } = props;
  return <S.RadioGroup>{children}</S.RadioGroup>;
}

export default function RadioButton(props) {
  const {
    className,
    name,
    value,
    checked,
    onChange,
    label,
    labelExtra,
    field,
    children,
  } = props;

  return (
    <S.RadioButton className={className}>
      <input
        type="radio"
        name={name || field?.name}
        value={value || field?.value}
        checked={checked}
        onChange={onChange || field?.onChange}
      />
      {label && label}
      {labelExtra && <label className="extra">{labelExtra}</label>}
      {children}
      <span className="dot" />
    </S.RadioButton>
  );
}

const S = {};

S.RadioGroup = styled.div``;

S.RadioButton = styled.label`
  color: ${({ theme }) => theme.white};
  font-size: 1.7rem;
  line-height: 1.7rem;
  display: block;
  margin-bottom: 1rem;
  flex: 0 0 auto;
  align-items: center;
  position: relative;
  user-select: none;
  .extra {
    padding-left: 1rem;
    color: #768594;
    font-weight: normal;
    font-size: 1.7rem;
    line-height: 1.7rem;
    pointer-events: none;
  }
  input {
    cursor: pointer;
    opacity: 0;
    margin: 0;
    max-width: 1.6rem;
    max-height: 1.6rem;
    flex: 0 0 auto;
    margin-right: 1rem;
  }
  .dot {
    pointer-events: none;
    position: absolute;
    top: 0.2rem;
    left: 0;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    background-color: transparent;
    box-shadow: inset 0 0 0 0.1rem #b1bac2;
  }
  input:checked ~ .dot {
    box-shadow: inset 0 0 0 0.1rem #ffffff, inset 0 0 0 0.3rem #212428,
      inset 0 0 0 0.7rem #00ab85;
  }
  &:hover {
    .dot {
      box-shadow: inset 0 0 0 0.1rem #ffffff;
    }
    input:checked ~ .dot {
      box-shadow: inset 0 0 0 0.1rem #b1bac2, inset 0 0 0 0.3rem #212428,
        inset 0 0 0 0.7rem #00ab85;
    }
  }
`;
