import CheckSVG from "images/brand-check.svg";
import React from "react";
import styled from "styled-components";

export default function Checkbox(props) {
  const {
    className,
    name,
    value,
    checked,
    disabled,
    onChange,
    label,
    labelExtra,
    field,
    children,
  } = props;

  return (
    <S.Checkbox className={className}>
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        {...field}
      />
      <span>
        {label && label}
        {labelExtra && <label className="extra">{labelExtra}</label>}
        {children}
      </span>
    </S.Checkbox>
  );
}

const S = {};

S.Checkbox = styled.label`
  color: ${({ theme }) => theme.white};
  font-size: 1.7rem;
  line-height: 1.7rem;
  margin-bottom: 1rem;
  display: flex;
  input {
    appearance: none;
    cursor: pointer;
    transition: none;
    margin-right: 1.2rem;
    outline: 0;
    background: transparent;
    height: 1.4rem;
    max-width: 1.4rem;
    border: 1px solid #b1bac2;
    &:hover {
      border: 1px solid #ffffff;
    }
    &:disabled {
      opacity: 0.5;
    }
    &:checked {
      border: 1px solid #ffffff;
      background: url(${CheckSVG}) no-repeat 1px 2px / 10px;
      &:hover {
        border: 1px solid #b1bac2;
      }
    }
    &.indeterminate {
      border: 1px solid #b1bac2;
      background: #b1bac2 url(${CheckSVG}) no-repeat 1px 3px / 12px;
    }
  }
  .extra {
    padding-left: 1rem;
    color: #768594;
    font-weight: normal;
    font-size: 1.7rem;
    line-height: 1.7rem;
    pointer-events: none;
    display: inline;
  }
  &.level1 {
    margin-bottom: 0.5rem;
  }
`;
